<template>
  <div class="newsn-continer">
    <div class="newsn-order">
      <div class="order-a">
        <div class="order-po"></div>
        订单流程
      </div>
      <div class="orderc">
        <div class="order-b" v-for="item in arr " :key="item.nodeId">
        <div class="order-x">
          <el-dropdown >
            <span class="el-icon-more"> </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item  command="a"  @click.native="comm(item.tipId)">配置</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="size-icon">
          <img class="size-img" src="../../assets/images/icon_jianctc.png" alt="">
        </div>
        <div class="order-text">{{item.name}}</div>
        <div class="order-raido">
         <el-switch
  v-model="item.enable"
  active-color="#13ce66"

  inactive-color="#ff4949"
   @change="parens2($event,item.tipId)">
</el-switch>

        </div>
      </div>
      </div>
    </div>
    <!-- <div class="newsn-order">
      <div class="order-a">
        <div class="order-po"></div>
        群发消息
      </div>
      <div class="order-c">
          <div class="mass-text">
            <div style=" margin-bottom:5px;">群发消息</div>
            <div>将消息推送给商户</div>
          </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import {newGetList,SetEnable} from '../../api/newsn'
export default {
  data() {
    return {
      radio: true,
      radio2: true,
        value: true,
        arr:[],
        tipId:Number(),//主键id
        status:Number(),//启用状态
    };
  },
  methods: {
    // handleCommand(command) {
    //  console.log(command)
    //  if(command==='a'){
    //   //  this.$router.push({path:'/newset',query:{}})
    //  }
    // },
    comm(val){
       this.$router.push({path:'/newset',query:{id:val}})
    },
    //获取列表
    getlist(){
      newGetList({}).then(res=>{
        console.log(res)
        if(res.code===10000){
          this.arr=res.data
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    //启用
    parens2(val,id){
      console.log(val,id)
      this.tipId=id
      this.status=val

      let data={
        id:this.tipId,
        enable:this.status
      }
      SetEnable(data).then(res=>{
        console.log(res)
      }).catch(err=>{
         console.log(err)
      })
    },
  },
  created(){
    this.getlist()
  }
};
</script>

<style scoped>
.newsn-continer {
  width: 100%;
  height: 100%;
  padding: 30px 0 0 30px;
  box-sizing: border-box;
  background: white;
  margin-top: 10px;
}
.newsn-order{
  margin-bottom: 35px;
}
.order-a {
  text-indent: 20px;
  position: relative;
  font-weight: 700;
}
.order-po {
  width: 5px;
  height: 20px;
  background: #ff9900;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: -2px;
}
.order-b {
  width: 317px;
  height: 97px;
  border: 1px solid #dddd;
  margin-top: 20px;
  position: relative;
}
.order-x {
  position: absolute;
  right: 11px;
  top: 0;
}
.size-icon {
  font-size: 50px;
  margin-left: 20px;
  float: left;
  margin-top: 10px;
}
.order-text {
  float: left;
  margin-left: 20px;
  line-height: 97px;
  font-size: 22px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #333333;
}
.order-raido {
  float: left;
  margin-left: 17px;
  margin-top: 36px;
  margin-left: 62px;
}
.size-img{
  width: 65px;
  height: 65px;
}
.order-c{
width: 317px;
height: 97px;
margin-top: 20px;
background-image:url('../../assets/images/img_beij@2x.png') ;
background-repeat:no-repeat;
background-size: 317px 97px;
overflow: hidden;
}
.mass-text{
  width: 146px ;
  height: 44px;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #0084FF;
}
.order-b{
  float: left;
  margin-left: 20px;
}
</style>
